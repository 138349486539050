import * as React from "react";
import { graphql } from "gatsby";
import TemplatePage from "../../../../components/pages/templates/TemplatePage";

const Template = ({ data }) => (
  <TemplatePage
    title="Music Streaming Website Template | Uizard"
    category="Web App Templates"
    url="/templates/web-app-templates/music-streaming-web-app/"
    metaDescription="Looking to emulate Spotify? Want to design your own music streaming web app? Our design template has everything you need to start your project."
    description="
    h2:Introducing AudioX, our music streaming website design template
    <br/>
    AudioX is a music streaming web app template for anyone designing a modern streaming media service. Whether you are building a new podcast web app or a new music streaming web app, AudioX has everything you could possibly need for <a:https://uizard.io/prototyping/>UX/UI prototyping</a> your own design.
    <br/>
    h3:A music streaming web app template that emulates the greats
    <br/>
    If you are interested in building your own media streaming service, you definitely want to borrow the winning design principles used by the most popular music streaming services out there. The web app version of AudioX gives you just that!
    <br/>
    h3:Design your own music streaming web app in seconds
    <br/>
    Uizard <a:https://uizard.io/templates/web-app-templates/>web design templates</a>come with all the must-have design and UI considerations baked in, making your design flow quicker and easier than ever before. Simply sign up to Uizard, open the template, and get to work designing your project.
    "
    pages={[
      "Screens of the login flow (sign-up, sign-in, user registration)",
      "Home screen",
      "Content search screen",
      "Personal library screen",
      "Screen for showcasing the profile of artists/content creators",
      "Screen for listing all the work of a specific artist/content creator",
    ]}
    projectCode="5E9bxL9Zdzhgpr7PEn6K"
    img1={data.image1.childImageSharp}
    img1alt="music streaming web app design template cover"
    img2={data.image2.childImageSharp}
    img2alt="music streaming web app design template artist screen"
    img3={data.image3.childImageSharp}
    img3alt="music streaming web app design template album screen"
    img4={data.image4.childImageSharp}
    img4alt="music streaming web app design template discover screen"
    img5={data.image5.childImageSharp}
    img5alt="music streaming web app design template summary screen"
  />
);

export default Template;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: {
        eq: "templates/music-streaming-web-app/music-streaming-web-app-cover.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: {
        eq: "templates/music-streaming-web-app/music-streaming-web-app-artist.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: {
        eq: "templates/music-streaming-web-app/music-streaming-web-app-album.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: {
        eq: "templates/music-streaming-web-app/music-streaming-web-app-discover.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: {
        eq: "templates/music-streaming-web-app/music-streaming-web-app-summary.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
